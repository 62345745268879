@import '~aos/dist/aos.css';

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#navbar-sticky a {
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.mobile-menu {
  position: fixed;
  top: 86px;
  width: 100%;
  z-index: 100;
  height: 100vh;
}

.mobile-menu .menu {
  position: fixed;
  width: 100%;
  max-width: 250px;
  height: 100vh;
  top: 0px;
  background: rgba(0, 0, 0, 0.925);
  z-index: 100;
  flex-direction: column;
  align-items: flex-start;
  padding: 80px 20px;
  display: flex;
  transition: all 500ms ease-in-out;
}

.mobile-menu li {
  position: relative;
  color: white;
  margin-bottom: 40px;
}

.mobile-menu a.active {
  font-weight: 600;
  font-size: 16px;
}

.mobile-menu a.active:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -10px;
  left: -23px;
  background-color: white;
  transition: all 0.3s ease-in-out 0s;
  transform: scaleX(0.7);
  transition: all 0.3s ease-in-out 0s;
}

.hideMenuNav {
  right: -200vw;
}

.showMenuNav {
  right: 0px;
}

.head {
  position: fixed;
  z-index: 10;
}

.head img.logo {
  height: 66px;
  width: 135px;
}

.head button {
  background-color: #121212;
  transition: all 500ms;
}

.head button:hover {
  background-color: #2EB233;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#navbar-sticky>ul>li {
  position: relative;
}

#navbar-sticky>ul>li>a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -10px;
  left: 0;
  background-color: black;
  visibility: hidden;
  transition: all 0.3s ease-in-out 0s;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

#navbar-sticky a:hover:before,
#navbar-sticky li:hover>a:before,
#navbar-sticky .active:before {
  visibility: visible;
  transform: scaleX(0.7);
}

#navbar-sticky a:hover,
#navbar-sticky .active,
#navbar-sticky .active:focus,
#navbar-sticky li:hover>a {
  color: black;
  font-weight: 600;
}

.hero {
  background-color: #201710;
  background-image: url('../public/assets/home.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.heroo {
  background-color: #201710;
  background: linear-gradient(90deg, #121212 19.05%, rgba(0, 0, 0, 0.44) 286.09%), url('../public/assets/Group 35.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.heading-rounded {
  background-color: #828282;
  font-size: 24px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.bannerwhattext {
  font-size: 16px;
}

.legal {

  font-style: normal;
  font-weight: 600;
  font-size: 24px;
}

.agm {
  font-size: 24px;
  font-weight: 400;

  font-style: normal;
}


.agm_27_2021_li {
  margin-bottom: 16px;
}

.carrer-box {
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(46, 179, 51, 0.3);
  max-width: 373.33px;
  min-height: 398px;
  border-radius: 8px;
  border: 2.5px solid transparent;
  transition: all 200ms;
}

.carrer-box:hover {
  border: 2.5px solid #2EB233;
  box-shadow: 0px 0px 15px rgba(46, 179, 51, 0.3);
}

.carrer-box img {
  width: 60px;
}

.carrer-box .carrer-button {
  border: 1px solid #2EB333;
  border-radius: 8px;
  padding: 8px 16px;
  color: #2EB233;
}

.carrer-box .carrer-button:hover {
  background-color: #2EB233;
  color: white;
}

.heading1 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  color: white;
}

.bannerwhat {
  background-color: #1D1D1D;
}

.bannerwhat.ir-1 {
  background-image: url('../public/assets/ir-3.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.bannerwhat.ir-2 {
  background-image: url('../public/assets/ir-2.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.bannerwhat.wwd-1 {
  background-image: url('../public/assets/what-we-do-img.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.bannerwhat.wwd-2 {
  background-image: url('../public/assets/ir-2.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.ensure {
  background-color: #0A0A0A;
}

.whole {
  font-size: 32px;
}

.wholesec {
  font-size: 16px;
  font-weight: 400;
}

.whol {
  background: linear-gradient(90deg, #121212 19.05%, rgba(0, 0, 0, 0) 286.09%);
}

.focus {
  font-size: 40px;
}

.border2 {
  opacity: 0.2;
  border: 1px solid #0A0A0A;
  width: 100%;
}

.footbor {
  border: 1px solid #262626;
}

.asset {
  background: #FFFFFF;
  border-radius: 15px;
  font-size: 20px;
}

.heading2 {
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  color: white;
}

.auction {
  font-size: 40px;
}

.notice-box {
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 373.33px;
  border-radius: 8px;
  transition: all 300ms;
  min-height: 288px;
}

.notice-box .assets {
  display: flex;
  padding: 24px 16px;
  opacity: 0.8;
  transition: all 300ms;
}

.notice-box .assets .heading {
  color: #0A0A0A !important;
}

.notice-box .assets div {
  line-height: 35px;
}

.notice-box .assets {
  border: 1px solid #a0a0a0;
  border-radius: 10px;
  margin: 20px;
}

.notice-box:hover {
  border: 1.5px solid #2EB233;
  box-shadow: 0px 0px 15px rgba(46, 179, 51, 0.3);
}

.notice-box .info-box {
  opacity: 0.6;
  padding-top: 24px;
}

.notice-box .assets:hover {
  background-color: #2EB233;
  opacity: 1;
  color: white !important;
}

.notice-box .assets p:hover {
  color: white !important;
}

.notice-box .notice {
  font-size: 18px;
  line-height: 35px;
  color: #0A0A0A;
  text-decoration: underline;
  transition: all 300ms;
  margin-bottom: 16px;
}

.notice-box .notice:hover {
  color: #2EB233;
}

.notice-box .date {
  color: #0A0A0A;
  font-size: 18px;
}

.notice-box .date:hover {
  color: #2EB233;
}

.phone {
  color: #2EB233;
}


.heading3 {
  background: #2EB333;

  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: white;
}

.col-span-5 {
  height: 100vh;
  background-color: #121212;
}

.back {
  background-color: #1D1D1D;
}

.bg-secondary {
  background-color: #fafafa;
}

.policy-box {
  box-sizing: border-box;
  padding: 24px 16px;
  width: 100%;
  max-width: 373px;
  height: 250px;
  border: 1.5px solid #cccccc;
  border-radius: 15px;
  transition: all 200ms;
  display: flex;
  flex-direction: column;
}

.policy-boxx {
  box-sizing: border-box;
  padding: 24px 16px;
  width: 100%;
  max-width: 373px;
  height: 156px;
  border: 1.5px solid #cccccc;
  border-radius: 15px;
  transition: all 200ms;
}

.policy-box .policies {
  color: #515151;
}

.policy-box img {
  height: 80px;
  width: 80px;
}

.policy-box:hover {
  cursor: pointer;
  border: 1.5px solid #2EB233;
  box-shadow: 0px 0px 15px rgba(46, 179, 51, 0.3);
  transform: translateY(-10px);
}

.policy-box button:hover {
  background-color: #2EB233;
}


.butto {
  border-color: #2EB233;
  color: #2EB233;
}

.uppercase {
  color: white;
  font-weight: 700;
}

.text-gray-500 {
  color: #828282 !important;
  font-family: 'Plus Jakarta Sans';
}

.put {
  font-style: normal;
}

.general {
  font-style: normal;
  font-size: 22px;
}

.ensuring {
  font-size: 40px;
}

.operating {
  font-style: normal;
  font-size: 40px;
}

.updates-box {
  min-height: max-content;
  background: #FFFFFF;
  border: 1.5px solid #cccccc;
  border-radius: 15px;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 24px;
}

.updates-box .fy {
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 16px;
  background: #121212;
  border: 1px solid #000000;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  transition: all 200ms;
}

.updates-box .fy:hover {
  cursor: pointer;
  background: #2EB233;
  border: 1px solid #2EB233;
}

.grev {
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
}

.prav {
  background: white !important;
}

.com {
  background: white !important;
}

.coming {
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

.cominghead {
  font-weight: 700;
  font-style: normal;
  font-size: 67px;
}

.name {
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
}

.points li {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.pointss {
  font-weight: 400;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-size: 16px;
  list-style: none;
}

.points {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.bodhead {
  font-weight: 600;
  font-style: normal;
  font-size: 28px;
  list-style: none;
}

.bodpara {
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  list-style: none;
}

.careers {
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}



.newsec {
  background-image: url('../public/assets/home2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: right;
  background-color: #0A0A0A;
}

.wilful {
  line-height: 25px;
}

.investor-boxes {
  box-sizing: border-box;
  padding: 24px 16px;
  width: 100%;
  max-width: 274px;
  height: 293px;
  border: 1.5px solid #cccccc;
  border-radius: 15px;
  transition: all 200ms;
}

.investor-boxes:hover {
  cursor: pointer;
  border: 1.5px solid #2EB233;
  box-shadow: 0px 0px 15px rgba(46, 179, 51, 0.3);
  transform: translateY(-10px);
}

.contact-box {
  width: 100%;
  max-width: 373.33px;
  min-height: 318px;
  padding: 32px;
  box-shadow: 0px 0px 15px rgba(46, 179, 51, 0.3);
  border-radius: 8px;
}

.designation {
  color: #1e1e1e;
}

.major {
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.dirhead {
  font-weight: 600;
  font-style: normal;
}

.with {
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  color: #1D1D1D;
}

.bannertext {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
}

.list li {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.fairrom li {
  list-style-type: upper-alpha;
}

.fairlow li {
  list-style-type: lower-alpha !important;
}

.fairalp li {
  list-style-type: lower-roman;
}

.fairnum li {
  list-style-type: decimal;
}

.nestedlist ol {
  counter-reset: item;

}

.nestedlist li {
  display: block;
}

.nestedlist li::before {
  content: counters(item, ".") " ";
  counter-increment: item
}

.goto-home-btn {
  padding: 10px 20px;
  background: #2EB233;
  border-radius: 8px;
  width: 294px;
  height: 41px;
  color: white;
}

.recovery-agent {
  border-radius: 8px;
  /* overflow: hidden; */
}

.recovery-agent thead {
  background: #0A0A0A;
  color: white;
  position: sticky;
  top: 0;
}

.recovery-agent thead th {
  padding: 16px 24px;
  text-align: left;
}

.recovery-agent tbody {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.recovery-agent tbody td {
  padding: 32px 24px;
}

.recovery-agent tbody tr.bg-alt {
  background-color: rgba(46, 178, 51, 0.1);
}

.bullet ol {
  list-style-type: decimal;
}

.bullet ul {
  list-style-type: disc;
}